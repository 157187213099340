<template>
  <b-autocomplete
    v-model="search"
    @input="handleInput"
    :data="data"
    placeholder="(Route ID)"
    field="id"
    ref="selector"
    :loading="isFetching"
    @keyup.native="searchRoute"
    @select="selectOption"
    :required="isrequired == true"
  >
    <template slot-scope="props">
        <div class="media">
        <div class="media-content">
          {{ props.option.departure}} - {{ props.option.destination}}
          <br>
          <small>
            <b>STAR: {{ props.option.star }}</b>
          </small>
        </div>
      </div>
    </template>
  </b-autocomplete>
</template>

<script>
import debounce from "debounce";

export default {
  props: ['isrequired', 'inicial'],
  data() {
    return {
      data: [],
      search: this.value,
      selected: null,
      isFetching: false,
      routeId: null
    };
  },
  mounted: function() {
    if (this.inicial) {
      this.search = this.inicial;
      // this.searchRoute();
    }
  },
  methods: {
    // Necesario para asignar v-model
    handleInput(e) {
      this.$emit("input", this.search);
    },

    searchRoute: debounce(function() {
      if (this.search == '') {
        this.data = [];
        this.selectOption({});
        return;
      }
      this.isFetching = true;
      this.$http
        .get(`${process.env.VUE_APP_HOST}/routes/search/${this.search}`)
        .then(
          (response) => {
            this.isFetching = false;
            this.data = response.data.data;
            if (this.data.length == 1) {
              this.selectOption(this.data[0]);
            } else if (this.data.length == 0) {
              this.selectOption({});
            }
            // console.log(this.route);
          },
          response => {
            this.isFetching = false;
          }
        );
    }, 500),
    selectOption(option) {
      this.selected = option;
      this.$emit("selectedOption", this.selected);
    }
  }
};
</script>
<style>
</style>