<template>
  <nav class="level">
    <div class="level-item has-text-centered">
      <div>
        <p class="heading">Departure</p>
        <p class="title">
          {{
            route.departure_icao ? route.departure_icao : "-"
          }}</p>
      </div>
    </div>
    <div class="level-item has-text-centered">
      <div>
        <p class="heading">Sid</p>
        <p class="title">{{
            route.sid_name ? route.sid_name : "-"
          }}</p>
      </div>
    </div>
    <div class="level-item has-text-centered">
      <div>
        <p class="heading">Sid Transition</p>
        <p class="title">
          {{
            route.sid_transition_name ? route.sid_transition_name : "-"
          }}</p>
      </div>
    </div>


    <div class="level-item has-text-centered">
      <div>
        <p class="heading">Star</p>
        <p class="title">{{
            route.star_name ? route.star_name : "-"
          }}</p>
      </div>
    </div>
    <div class="level-item has-text-centered">
      <div>
        <p class="heading">Star Transition</p>
        <p class="title">
          {{
            route.star_transition_name ? route.star_transition_name : "-"
          }}</p>
      </div>
    </div>
    <div class="level-item has-text-centered">
      <div>
        <p class="heading">Destination</p>
        <p class="title">
          {{
            route.destination_icao ? route.destination_icao : "-"
          }}</p>
      </div>
    </div>
    <div class="level-item has-text-centered">
      <div>
        <p class="heading">Distance</p>
        <p class="title">
          {{ route.distance ? route.distance : "-" }}</p>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "RouteNavDisplay",
  props: {
    route: Object
  }
}
</script>

<style scoped>

</style>