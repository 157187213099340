<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title" >Route Search</p>
        </header>
        <section class="modal-card-body">
          <RoutePaginatedSelector
            v-on:onRouteSelected="onRouteSelected($event)" :forcedDepartureIcao="forcedDepartureIcao"
          >
          </RoutePaginatedSelector>
        </section>
        <footer class="modal-card-foot modal-foot-right">
            <button type="button" class="button" @click="$parent.close()">
                <span class="has-text-weight-bold is-uppercase">Cerrar</span>
            </button>
        </footer>
    </div>
</template>
<script>

import RoutePaginatedSelector from "../RoutePaginatedSelector.vue";

export default {
    name: 'RouteSearchModal',
    props: ['forcedDepartureIcao'],
    data() {
        return {
        };
    },
    computed: {
    },
    methods: {
      onRouteSelected(route) {
        this.$emit('onRouteSelected', route);
        this.$parent.close();
      }
    },
    components: {
      RoutePaginatedSelector
    }
};
</script>
<style>
.modal-content {
  width: 1200px;
  height: 800px;
}
.modal-card {
   width: 1200px;
 }
</style>