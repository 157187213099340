import ApiService from "./api.service"

export default class FleetService extends ApiService {
    path = 'fleets';

    listAircrafts(fleetId) {
        return this.http.get(`${this.url}/${fleetId}/aircrafts`);
    }
    paginate(params) {
        return this.http.get(`${this.url}/paginate`, { params: params } );
    }
    selectors() {
        return this.http.get(`${this.url}/selectors`);
    }
}