<template>
  <div>
    <b-autocomplete
      v-model="search"
      @input="handleInput"
      :data="data"
      placeholder="e.g. SCSL"
      field="icao"
      :loading="isFetching"
      @keyup.native="searchAirport"
      @select="selectOption"
      :disabled="isdisabled == true"
    >
      <template slot-scope="props">
        <div class="media">
          <div class="media-content">
            {{ props.option.icao }}
            <br>
            <small>
              <b>{{ props.option.name }}</b>
            </small>
          </div>
        </div>
      </template>
    </b-autocomplete>
  </div>
</template>

<script>
import debounce from "debounce";

export default {
  props: ['isdisabled'],
  data() {
    return {
      data: [],
      search: this.value,
      selected: null,
      isFetching: false,
      airportId: null
    };
  },
  methods: {
    // Necesario para asignar v-model
    handleInput(e) {
      this.$emit("input", this.search);
    },

    searchAirport: debounce(function() {
      this.isFetching = true;
      this.$http
        .get(`${process.env.VUE_APP_HOST}/airports/search/${this.search}`)
        .then(
          response => {
            this.isFetching = false;
            this.data = response.data.airports;
            if (this.data.length == 1) {
              this.selectOption(this.data[0]);
            }
          },
          response => {
            this.isFetching = false;
          }
        );
    }, 500),
    selectOption(option) {
      this.selected = option;
      this.$emit("selectedOption", this.selected);
    }
  }
};
</script>