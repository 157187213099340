<template>
    <section>
      <form action="#" autocomplete="off" v-on:submit.prevent="onFilter()">
        <div class="content">
            <div class="columns">
                <div class="column">
                    <label class="label">Departure</label>
                    <AirportSelect
                      v-model="filter.departure_icao"
                      ref="departure"
                      :isdisabled="!!forcedDepartureIcao"
                    ></AirportSelect>
                </div>
                <div class="column">
                    <label class="label">Destination</label>
                    <AirportSelect v-model="filter.destination_icao" ref="destination">
                    </AirportSelect>
                </div>
                <div class="column">
                    <label class="label">&nbsp;</label>
                    <button type="submit" class="button is-link is-fullwidth">
                        <span class="icon">
                            <i class="fas fa-filter"></i>
                        </span>
                        <span class="has-text-weight-bold is-uppercase">Filter Routes</span>
                    </button>
                </div>
            </div>
        </div>
      </form>
        <b-table
            paginated
            backend-pagination
            backend-sorting
            :data="pagination.records"
            :loading="pagination.loading"
            :total="pagination.total"
            :per-page="pagination.perPage"
            :default-sort-direction="pagination.defaultSortOrder"
            :default-sort="[pagination.sortField, pagination.sortOrder]"
            @page-change="onPageChange"
            @sort="onSort">
            <template slot-scope="props">
                <b-table-column field="id" label="Id" sortable>
                    {{ props.row.id }}
                </b-table-column>
                </b-table-column>
                <b-table-column field="departure" label="Departure" sortable>
                    {{props.row.departure_icao}}
                </b-table-column>
                <b-table-column field="sid" label="Sid" sortable>
                    {{ props.row.sid_name}}
                </b-table-column>
                <b-table-column field="sid_transition" label="Sid Transition" sortable>
                    {{ props.row.sid_transition_name }}
                </b-table-column>
                <b-table-column field="star" label="Star" sortable>
                    {{ props.row.star_name }}
                </b-table-column>
                <b-table-column field="star_transition" label="Star Transition" sortable>
                    {{ props.row.star_transition_name }}
                </b-table-column>
                <b-table-column field="destination" label="Destination" sortable>
                    {{ props.row.destination_icao }}
                </b-table-column>
              <b-table-column field="distance" label="Distance" sortable>
                    {{ props.row.distance }}
                </b-table-column>
                <b-table-column label="Actions">
                  <button type="button"
                          class="button is-small is-success"
                          v-on:click="onSelectRoute(props.row)">
                      Select
                  </button>
                </b-table-column>
            </template>
        </b-table>
    </section>
</template>
<script>
import RoutesService from "../services/routes.service.js";
import AirportSelect from "./AirportSelect.vue";
import RoutesPaginationFilter from "../filters/routes-pagination.filter";

export default {
    name: "RoutesIndex",
    props: ['forcedDepartureIcao'],
    components: { AirportSelect },
    data() {
        return {
            isLoading: false,
            routesService: new RoutesService(),
            pagination: {
                records: [],
                total: 0,
                loading: false,
                sortField: 'id',
                sortOrder: 'desc',
                defaultSortOrder: 'asc',
                page: 1,
                perPage: 7
            },
            filter: new RoutesPaginationFilter(this.forcedDepartureIcao),
        };
    },
    methods: {
        onReset() {
            this.filter.reset();
            this.paginate();
        },
        onFilter () {
            this.paginate();
        },
        paginate() {
            const params = {
                sort_field: this.pagination.sortField,
                sort_order: this.pagination.sortOrder,
                per_page: this.pagination.perPage,
                page: this.pagination.page
            };
            if (!this.filter.isEmpty()) {
                params.filter = this.generateFilters();
            }
            this.filter.departure_icao = this.forcedDepartureIcao;
            this.pagination.loading = true;
            this.routesService.paginate(params).then(
                ({data}) => {
                    this.pagination.records = data.data;
                    this.pagination.total = data.meta.total;
                    this.pagination.loading = false
                },
                () => {
                    this.pagination.records = []
                    this.pagination.total = 0
                    this.pagination.loading = false
                    this.$buefy.toast.open({
                        message: 'Ha ocurrido un error al paginar!!!',
                        type: 'is-danger'
                    });
                }
            );
        },
        /*
        * Handle page-change event
        */
        onPageChange(page) {
            this.pagination.page = page;
            this.paginate();
        },
        /*
        * Handle sort event
        */
        onSort(field, order) {
            this.pagination.sortField = field;
            this.pagination.sortOrder = order;
            this.paginate();
        },
        onDeleteRoute(route) {
            this.$buefy.dialog.confirm({
                title: 'Deleting Route',
                message: `This will delete the route: </br><b>Departure: ${route.departure}</br>Sid: ${ route.sid}</br>Sid Transition: ${ route.sidTransition }</br>Star: ${ route.star }</br>Star Transition: ${ route.starTransition }</br>Destination: ${route.departure}</b></br>from the database, are you sure?`,
                cancelText: 'Cancel',
                confirmText: 'Yes, delete',
                type: 'is-danger',
                size: 'is-medium',
                // width: 600,
                hasIcon: true,
                onConfirm: () => {
                    const loadingComponent = this.$buefy.loading.open({container: null})
                    this.routesService.anular(solicitud.id).then(
                        ({data}) => {
                            loadingComponent.close();
                            this.paginate();
                            this.$buefy.toast.open({
                                message: data.notificacion,
                                type: 'is-success'
                            });
                        },
                        () => {
                            loadingComponent.close();
                            this.paginate();
                            this.$buefy.toast.open({
                                message: 'An error ocurred while deleting the route.',
                                type: 'is-danger'
                            });
                        }
                    );
                }
            });
        },
        generateFilters () {
            var filtro = {};
            if (this.filter.hasValue(this.filter.departure_icao)) {
                filtro.departure_icao = this.filter.departure_icao;
            }
            if (this.filter.hasValue(this.filter.destination_icao)) {
                filtro.destination_icao = this.filter.destination_icao;
            }
            return filtro;
        },
        onSelectRoute(route) {
          this.$emit('onRouteSelected', route);
        },
    },
    mounted() {

      if (this.forcedDepartureIcao) {
        this.$refs['departure'].search = this.forcedDepartureIcao;
      }
      this.paginate();
    }
}
</script>